<template>
  <div v-if="config" class="app-container">
    <div class="scroll-wrap">
      <div v-if="!orderInfo" class="header-box">
        <div class="mask">
          <div class="lock" />
          <div class="text">当前内容购买后才能观看</div>
        </div>
        <img v-if="!config.bannerBgUrl" src="~@/assets/images/banner.png" alt="">
        <img v-else :src="oss2cdn(config.bannerBgUrl)" alt="">
      </div>
      <div v-else class="header-box">
        <div v-if="videoMaskVisible" class="mask">
          <div class="play-btn" @click="handlePlayVideo" />
        </div>
        <video id="video" ref="myVideo" controls="false" src="~@/assets/video/wechat-header-video.mp4" preload :poster="config.bannerBgUrl" playsinline webkit-playsinline x5-video-player-type="h5-page" />
      </div>
      <div class="goods-info">
        <div class="price-box">
          <span>￥</span>
          <em>{{ (price/100).toFixed(2) }}</em>
          <strong>原价¥299</strong>
        </div>
        <div v-if="channelId != 1" class="goods-title">12天小白理财训练营</div>
        <!-- 微信公众号渠道投放使用商品名称 -->
        <div v-else class="goods-title">{{ goodsName }}</div>
      </div>
      <div class="divider" />
      <div v-if="salesmanInfo" class="add-salesman">
        <div class="add-btn" @click="setSalesmanDialogVisible">添加助教，进群学习</div>
      </div>
      <div v-if="salesmanInfo" class="divider" />
      <img v-if="!config.contentBgUrl" src="~@/assets/images/content.png" alt="">
      <img v-else :src="oss2cdn(config.contentBgUrl)" alt="">
    </div>
    <div class="bottom">
      <div class="left" @click="customService">
        <span />
        <em>联系客服</em>
      </div>
      <div class="right bottom-pay-btn" :class="{disabled: orderInfo}" @click="handlePaySmallClass">立即报名</div>
    </div>
    <div v-if="customServiceDialogVisible" class="custom-service-dialog">
      <div class="dialog-box">
        <div class="close" @click="customServiceDialogVisible = false">X</div>
        <div class="qrcode">
          <img :src="config.customServiceQrCodeUrl" alt="">
        </div>
        <div class="fingerprint" />
        <div class="finger-arrow">
          <span />
          <em>长按识别二维码添加客服微信</em>
        </div>
      </div>
    </div>
    <div v-if="salesmanDialogVisible" class="custom-service-dialog">
      <div class="dialog-box">
        <div class="close" @click="salesmanDialogVisible = false">X</div>
        <div class="qrcode">
          <img :src="oss2cdn(salesmanInfo.wxUrl)" alt="">
        </div>
        <div class="fingerprint" />
        <div class="finger-arrow">
          <span />
          <em>长按识别二维码添加助教微信</em>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Landing} from '@/network'
import {Toast} from 'vant'
import {device, oss2cdn} from '@/utils'

const CONFIG = {
  bannerBgUrl: null,
  contentBgUrl: null,
  customServiceQrCodeUrl: null
}

export default {
  data() {
    return {
      config: null,
      price: 0,
      phone: null,
      channelId: null,
      goodsName: null,
      orderInfo: null,
      customServiceDialogVisible: false,
      salesmanDialogVisible: false,
      videoMaskVisible: true,
      salesmanInfo: null,
      oss2cdn
    }
  },
  activated() {
    // 检查是否有已完成订单
    this.handleCheckOrder()
  },
  async created() {
    // 加载图标
    const configLoadingToast = Toast.loading({
      message: '页面加载中...',
      forbidClick: true,
      duration: 0,
      loadingType: 'spinner'
    })
    // 页面配置信息
    const {data} = await Landing.getLandingPageConfig(this.$route.params)
    configLoadingToast.clear()
    this.channelId = data.channelId
    this.price = data.price
    this.goodsName = data.name
    try {
      this.config = JSON.parse(data.presentConfig) || CONFIG
    } catch (error) {
      console.warn(error)
    }
    // 检查是否有已完成订单
    this.handleCheckOrder()
  },
  methods: {
    async handlePaySmallClass() {
      if (this.orderInfo) return Toast('您已经购买过该商品')
      const {data} = await Landing.paySmallClassByOpenId({
        callback: window.location.href,
        channelId: this.channelId,
        goodsId: this.$route.params.id,
        openid: window.initData.openid,
        device: device()
      })
      this.outTradeNo = data.outTradeNo
      this.$wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: data.paySign, // 支付签名
        success: () => {
          const query = {
            outTradeNo: this.outTradeNo,
            goodsId: this.$route.params.id
          }
          this.handleGetSmallOrder(3, 'WechatPayFail', query)
        },
        complete: () => {
          const query = {
            outTradeNo: this.outTradeNo,
            goodsId: this.$route.params.id
          }
          this.handleGetSmallOrder(3, 'WechatPayFail', query)
        }
      })
    },
    async handleGetSmallOrder(countdown = 3, failRouteName, query) {
      const {data} = await Landing.getSmallOrder({
        outTradeNo: this.outTradeNo
      })
      countdown--
      if ((!data || !data.salesmanId) && !countdown && failRouteName) return this.$router.push({name: failRouteName})
      if (!data || !data.salesmanId) return setTimeout(async() => await this.handleGetSmallOrder(countdown, failRouteName, query), 1000)
      this.$router.push({
        name: 'WechatPaySuccess',
        params: {salesmanId: data.salesmanId},
        query
      })
    },
    async handleCheckOrder() {
      const {data} = await Landing.checkOrder({openid: window.initData.openid})
      if (!data) return
      this.orderInfo = data
      // 获取订单中助教老师的信息
      this.handleGetSalesmanInfo()
    },
    customService() {
      if (!this.orderInfo) return (this.customServiceDialogVisible = true)
      this.$router.push({
        name: 'WechatQuestionList',
        query: {
          outTradeNo: this.orderInfo.outTradeNo,
          goodsId: this.$route.params.id
        }
      })
    },
    handlePlayVideo() {
      this.videoMaskVisible = false
      this.$refs.myVideo.play()
    },
    async handleGetSalesmanInfo() {
      const {data} = await Landing.getSalesmanInfo({id: this.orderInfo.salesmanId})
      this.salesmanInfo = data
    },
    setSalesmanDialogVisible() {
      this.salesmanDialogVisible = true
      this.handleSetReadFinish()
    },
    handleSetReadFinish() {
      const outTradeNo = this.orderInfo.outTradeNo
      setTimeout(() => {
        Landing.setReadFinish({outTradeNo})
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.scroll-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
  .header-box {
    width: auto;
    height: auto;
    position: relative;
    .mask {
      width: auto;
      height: auto;
      background: rgba(0, 0, 0, 0.46);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .lock {
        width: 24px;
        height: 24px;
        background-image: url('~@/assets/images/video-lock-icon.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px;
      }
      .text {
        width: auto;
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      .play-btn {
        width: 40px;
        height: 40px;
        background-image: url('~@/assets/images/video-play-icon.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px;
      }
    }
    #video {
      width: 100vw;
      height: 379px;
    }
  }
  .goods-info {
    width: 100%;
    height: 75px;
    box-sizing: border-box;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    .price-box {
      width: 100%;
      height: auto;
      vertical-align: middle;
      span {
        width: auto;
        line-height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #fd5800;
        margin-right: 3px;
      }
      em {
        width: auto;
        line-height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fd5800;
        font-style: normal;
        margin-right: 5px;
      }
      strong {
        width: auto;
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #919191;
        line-height: 16px;
        text-decoration: line-through;
      }
    }
  }
  .goods-title {
    width: auto;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
  }
  .divider {
    width: 100%;
    height: 8px;
    background: #f8f8f8;
  }
}
.bottom {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  .left {
    width: 115px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    span {
      width: 15px;
      height: 16px;
      background-image: url('~@/assets/images/custom-service-icon.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 8px;
    }
    em {
      width: auto;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-style: normal;
      color: #000000;
      line-height: 17px;
    }
  }
  .right {
    width: 260px;
    height: 50px;
    background: linear-gradient(90deg, #fec502 0%, #fe9161 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 25px;
    letter-spacing: 2px;
    &.disabled {
      background: linear-gradient(90deg, #FFE99E 0%, #FFBC9F 100%);
    }
  }
}
.custom-service-dialog {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dialog-box {
    width: 292px;
    height: 297px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 25px 0 30px;
    margin-top: 155px;
    position: relative;
    .close {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #000;
      position: absolute;
      left: 0;
      top: 0;
    }
    .qrcode {
      width: 180px;
      height: 180px;
      border-radius: 5px;
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      padding: 6px;
      margin-bottom: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .fingerprint {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/images/fingerprint-icon.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 12px;
    }
    .finger-arrow {
      width: auto;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 16px;
        height: 16px;
        background-image: url('~@/assets/images/finger-arrow-icon.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 1px;
      }
      em {
        font-style: normal;
      }
    }
  }
}
.add-salesman {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  .add-btn {
    width: 160px;
    height: 34px;
    background: #F84439;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
  }
}
</style>
